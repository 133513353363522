import { ReadFileBase64 } from '@/utils/files'

export default {
  async insert (Vue, idtabla, id, formData) {
    for (const file of formData.files) {
      await Vue.$api.call(
        'fichero.insert',
        {
          values: {
            data: await ReadFileBase64(file),
            nombre_fichero: file.name,
            idtabla,
            id,
            idtfichero: formData.idtfichero,
            observaciones: formData.observaciones,
            carpeta: formData.carpeta,
          },
        }
      )
    }
  },
}

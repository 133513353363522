import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectTfichero (Vue, esTecnico, esComercial, esVigilante) {
    const apiFilter = new APIFilter()
    apiFilter.addGT('estado', 0)
    const apiFilterVisualizacion = new APIFilter()
    apiFilterVisualizacion.setOperator(API_FILTER_OPERATORS.OR)
    if (esTecnico) {
      apiFilterVisualizacion.addExact('visible_movilidad_sat', esTecnico)
    }
    if (esComercial) {
      apiFilterVisualizacion.addExact('visible_movilidad_vigilancia', esComercial)
    }
    if (esVigilante) {
      apiFilterVisualizacion.addExact('visible_movilidad_sat', esVigilante)
    }
    apiFilter.addNestedFilter(apiFilterVisualizacion)
    const resp = await Vue.$api.call('tfichero.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idfichero', id)
    const resp = await Vue.$api.call('fichero.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectCarpetasDisponibles (Vue, idtabla, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('id', id)
      .addExact('idtabla', idtabla)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('fichero.selectCarpetasDisponibles', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}

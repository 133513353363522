<template>
  <b10-base>
    <ValidationProvider
      v-if="isInsert"
      v-slot="{ errors }"
      name="files"
      :rules="formRules.files"
    >
      <v-file-input
        v-model="form.files"
        label="Ficheros"
        show-size
        prepend-icon="attach_file"
        chips
        multiple
        :append-outer-icon="$vuetify.icons.values.camera"
        :disabled="capturedImage"
        :error-messages="errors"
        @change="filesChange"
        @click:append-outer="clickCamera"
      />
    </ValidationProvider>
    <b10-camera
      :showing.sync="showingCamera"
      :device-id="devicesCameraDeviceId"
      filename="fotografía.jpg"
      @click-shutter="clickShutter"
      @change-deviceid="changeCamera"
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="idtfichero"
      :rules="formRules.idtfichero"
    >
      <b10-autocomplete
        v-model="form.idtfichero"
        :items="tfichero"
        item-value="idtfichero"
        item-text="descripcion"
        label="Tipo de fichero"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
    <v-select
      v-model="carpetaExistente"
      :items="carpetasDisponibles"
      item-value="carpeta"
      item-text="carpeta"
      label="Carpeta"
      clearable
      @change="carpetaExistenteSelected"
    />
    <v-text-field
      v-show="crearCarpetaNueva"
      v-model="carpetaNueva"
      clearable
      flat
      label="Carpeta nueva"
      @change="carpetaNuevaChange"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './FicheroFormData'
import _ from '@/utils/lodash'
import { sync } from 'vuex-pathify'
import { PARAMETRO } from '@/utils/consts'
import  { extend } from 'vee-validate'
import { size as fileSizeValidation } from 'vee-validate/dist/rules'

export default {
  mixins: [formMixin],
  props: {
    entidadIdtabla: {
      type: [Number, String],
      required: true,
    },
    entidadId: {
      type: [Number, String],
      required: true,
    },
    esTecnico: {
      type: Boolean,
    },
    esComercial: {
      type: Boolean,
    },
    esVigilante: {
      type: Boolean,
    },
  },
  data () {
    const crearCarpetaOption = 'Crear carpeta nueva...'
    return {
      crearCarpetaOption,
      carpetasDisponibles: [ crearCarpetaOption ],
      crearCarpetaNueva: false,
      showingCamera: false,
      capturedImage: false,
      carpetaExistente: null,
      carpetaNueva: null,
      tfichero: [],
      form: {
        files: [],
        idtfichero: null,
        observaciones: null,
        carpeta: null,
      },
      formRules: {
        files: { required: true, tamanioFicheroAdjunto: true },
        idtfichero: { required: true },
      },
      tamanioMaxAdjuntoMB: 0,
    }
  },
  computed: {
    'devicesCameraDeviceId': sync('devices/cameraDeviceId'),
  },
  async created () {
    const maxAdjuntoMB = await this.$online.parametro.valorParametro(PARAMETRO.parametros.TAMANIO_MAX_ADJUNTOS_MB)
    if (maxAdjuntoMB) {
      this.tamanioMaxAdjuntoMB = parseInt(maxAdjuntoMB)
    }
    extend('tamanioFicheroAdjunto', {
      message: 'El fichero es demasiado grande',
      validate: async (value) => {
        if (this.tamanioMaxAdjuntoMB > 0) {
          return fileSizeValidation.validate(value, { size: this.tamanioMaxAdjuntoMB * 1024 })
        } else {
          return true
        }
      }
    })
    const [datasetTfichero] = await Data.selectTfichero(this, this.esTecnico, this.esComercial, this.esVigilante)
    this.tfichero = datasetTfichero
    const [carpetasDisponibles] = await Data.selectCarpetasDisponibles(this, this.entidadIdtabla, this.entidadId)
    this.carpetasDisponibles.push(..._.map(carpetasDisponibles, 'carpeta'))
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.$set(this.form, 'idtfichero', formData.idtfichero)
      this.$set(this.form, 'observaciones', formData.observaciones)
      this.$set(this.form, 'carpeta', formData.carpeta)
      this.carpetaExistente = formData.carpeta
    },
    filesChange (files) {
      this.$set(this.form, 'files', files)
    },
    clickCamera () {
      this.showingCamera = true
    },
    clickShutter (imgFile) {
      this.filesChange([imgFile])
      this.capturedImage = true
    },
    carpetaExistenteSelected (value) {
      this.crearCarpetaNueva = value === this.crearCarpetaOption
      if (value !== this.crearCarpetaOption) {
        this.$set(this.form, 'carpeta', value)
      }
    },
    carpetaNuevaChange (value) {
      if (this.carpetaExistente === this.crearCarpetaOption) {
        this.$set(this.form, 'carpeta', value)
      }
    },
    changeCamera (value) {
      this.devicesCameraDeviceId = value
    },
  },
}
</script>
